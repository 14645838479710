<template>
    <v-card>
        <v-card-title class="text-h5">{{ message }}</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn> -->
            <v-btn color="blue darken-1" text @click="close">OK</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</template>

<script>
export default ({
    props: {
        message: { type: String },
        close: { type: Function },
    },
})
</script>