<template>
    <v-card>
        <v-card-title class="delete__title">{{ $t('cms.confirm_delete_text') }}</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{ $t('cancel') }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{ $t('ok') }}</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        closeDelete: { type: Function },
        deleteItemConfirm: { type: Function },
    },
};
</script>

<style lang="scss">
.delete__title {
    font-size: 20px !important;
    justify-content: center;
}
</style>
